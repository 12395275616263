import React from 'react';
import { Row, Col } from 'antd';
import Link from 'next/link';
import { Category, Title } from 'components/ArticleCard';
import WhitePaperImage from 'components/WhitePaperImage';

interface Props {
    item: WhitePaper;
}

const WhitePaperCard = ({ item }: Props): JSX.Element => (
    <Row gutter={16}>
        <Col xs={8} md={24}>
            <Link href={`/white-papers/${item.seoSlug}`}>
                <WhitePaperImage openingImage={item.openingImage} title={item.title} />
            </Link>
        </Col>
        <Col xs={12} md={24}>
            <Category>Do pobrania</Category>
            <Title>
                <Link href={`/white-papers/${item.seoSlug}`}>{item.title}</Link>
            </Title>
        </Col>
    </Row>
);

export default WhitePaperCard;
