import React from 'react';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import Image from 'components/Image';

interface ContainerProps {
    $maxWidth?: number | string;
    $maxHeight?: number | string;
}

const Container = styled.div<ContainerProps>`
    max-width: ${({ $maxWidth }) => $maxWidth || '272px'};
    max-height: ${({ $maxHeight }) => $maxHeight || '272px'};
    width: 100%;
    position: relative;
    background: #b7cbd9;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    > img {
        display: block;
        height: auto;
        width: 100%;
        margin: 0 auto;
    }
`;

const Wrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
    padding: 6px 10px 4px 7px;
`;
interface Props {
    openingImage: string;
    title: string;
}

const WhitePaperImage = ({ openingImage, title }: Props): JSX.Element => {
    return (
        <Container>
            <Image
                type="white-paper"
                height={152}
                width={152}
                alt={title}
                src={openingImage || '/static/images/whitepaper-default.svg'}
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
            />
            <Wrapper>
                <DownloadOutlined />
            </Wrapper>
        </Container>
    );
};

export default WhitePaperImage;
