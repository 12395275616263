import styled from 'styled-components';
import { Card } from 'antd';

interface CardProps {
    readonly large?: boolean;
}

const CardStyled = styled(Card)<CardProps>`
    .ant-card-body {
        padding: 0 !important;
    }
    .ant-card-cover {
        height: ${({ large }: CardProps) => (large ? '319px' : '173px')};
        img {
            vertical-align: middle;
            max-width: ${({ large }: CardProps) => (large ? '569px' : '272px')};
            max-height: ${({ large }: CardProps) => (large ? '319px' : '173px')};
            width: 100%;
            height: auto;
            margin: auto;
        }
    }
`;

export default CardStyled;
