import React, { FC, useState } from 'react';
import styled from 'styled-components';
import getConfig from 'next/config';
import * as gtag from 'lib/gtag';
import isEmail from 'validator/lib/isEmail';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd/lib/grid';
import { merriweather } from 'styles/GlobalStyle';

const H5 = styled.h5`
    color: white;
    font-weight: bold;
    flex: 1;
    margin: auto;
    text-align: center;
    font-family: ${merriweather.style.fontFamily};
    font-size: 24px;
`;

const Container = styled(Row)`
    @media (min-height: 685px) {
        padding: 15px 0;
    }
    padding: 10px;
    border-radius: 22px;
    border: solid 2px #cb212f;
    background-color: #cb212f;
    div {
        margin: auto 0;

        @media (min-height: 685px) {
            padding: 0.2rem 0.5rem;
        }
        /* flex: 1 1 auto; */
        /* box-sizing: border-box; */
    }

    small {
        cursor: pointer;
    }
`;

const FormContainer = styled.div`
    position: relative;
    padding: 25px;
`;

type SubmitButtonProps = {
    $disabled: boolean;
};

const SubmitButton = styled.button<SubmitButtonProps>`
    border: solid 2px #707070;
    background-color: #fff;
    position: absolute;
    right: 0;
    line-height: 32px;
    font-size: 20px;
    height: 37px;
    cursor: pointer;
    :hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};
    }

    ${(props: SubmitButtonProps) =>
        props.$disabled &&
        `
            cursor: default;
            :hover {
                background-color: #fff;
                color: black;
            }
        `};
`;

const ErrorMessage = styled.div`
    color: rgba(255, 255, 255, 0.85);
    font-style: italic;
    font-weight: bold;
`;

interface ShowMoreProps {
    readonly $visible?: boolean;
}

const ShowMore = styled.span<ShowMoreProps>`
    visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    color: white;
    text-decoration: underline;
    font-weight: bold;
    padding: 10px 5px;
`;

interface AgreementLabelProps {
    readonly $collapsed?: boolean;
}

const AgreementLabel = styled.label<AgreementLabelProps>`
    display: block;
    color: ${(props) => props.theme.colors.white};
    margin: 8px 0 0 16px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.14px;
    text-align: left;
    ${(props: AgreementLabelProps) =>
        props.$collapsed &&
        `overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 70%;
    `};
`;

const InformationText = styled.div`
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: white;
    padding: 2rem 4rem 0 4rem;
    margin-bottom: 0;
    > p:first-of-type {
        font-weight: bold;
    }
`;

const Newsletter: FC = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [submitted, setSubmitted] = useState<boolean>(false);
    // const [submitError, setSubmitError] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState<string | undefined>();
    const [agreementError, setAgreementError] = useState<string | undefined>();

    const validateForm = (email: string, agreement: boolean) => {
        let isValid = true;
        if (!isEmail(email)) {
            isValid = false;
            setEmailError('To nie jest poprawny adres e-mail');
        } else {
            setEmailError(undefined);
        }

        if (!agreement) {
            isValid = false;
            setAgreementError('To pole jest wymagane!');
        } else {
            setAgreementError(undefined);
        }

        return isValid;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            email: { value: string };
            agreement: { checked: boolean };
        };

        const isValid = validateForm(target.email.value, target.agreement.checked);
        if (!isValid) {
            return;
        }

        setLoading(true);
        const { apiUrl } = getConfig().publicRuntimeConfig;
        fetch(`${apiUrl}/public/api/newsletter`, {
            method: 'post',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: target.email.value, terms: target.agreement.checked })
        })
            .then((response) => {
                if (!response.ok) throw response;

                return response.json();
            })
            .then(() => {
                gtag.event({
                    action: 'register',
                    category: 'Newsletter',
                    label: 'Newsletter',
                    value: 'registered'
                });
                setSubmitted(true);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Container>
                <Col xxl={8} xs={24}>
                    <H5>NEWSLETTER</H5>
                </Col>
                <Col xxl={16} xs={24}>
                    {!submitted && (
                        <FormContainer>
                            <form onSubmit={handleSubmit}>
                                <fieldset
                                    style={{ position: 'relative', border: 'none', padding: 0 }}
                                >
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Wpisz swój adres e-mail"
                                        style={{ fontSize: 20, width: '100%', height: 37 }}
                                        required={true}
                                        onFocus={() => setEmailError(undefined)}
                                    />
                                    <SubmitButton $disabled={loading} type="submit">
                                        {loading && <LoadingOutlined />}
                                        ZAPISZ SIĘ
                                    </SubmitButton>
                                </fieldset>
                                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                                <div style={{ position: 'relative' }}>
                                    <AgreementLabel
                                        htmlFor="newsletter-agreement"
                                        $collapsed={collapsed}
                                    >
                                        <input
                                            type="checkbox"
                                            id="newsletter-agreement"
                                            name="agreement"
                                            style={{
                                                verticalAlign: 'middle',
                                                position: 'relative',
                                                bottom: '1px',
                                                marginRight: '10px'
                                            }}
                                            onFocus={() => setAgreementError(undefined)}
                                        />
                                        Wyrażam zgodę na otrzymywanie informacji handlowej
                                        kierowanej do mnie za pomocą środków komunikacji
                                        elektronicznej w szczególności poczty elektronicznej zgodnie
                                        z przepisem art. 10 ust 2 ustawy z dnia 18 lipca 2002 roku o
                                        świadczeniu usług drogą elektroniczną (Dz. U. 144 z 2002 r.
                                        poz. 1204). Zgoda jest dobrowolna, jednak jej wyrażenie jest
                                        konieczne, aby otrzymywać newsletter.
                                    </AgreementLabel>
                                    <ShowMore
                                        $visible={collapsed}
                                        onClick={() => setCollapsed(false)}
                                    >
                                        Zobacz więcej
                                    </ShowMore>
                                </div>
                                {agreementError && <ErrorMessage>{agreementError}</ErrorMessage>}
                            </form>
                        </FormContainer>
                    )}
                    {submitted && (
                        <InformationText
                            onClick={() => {
                                setSubmitted(false);
                                setCollapsed(true);
                            }}
                        >
                            <p>Dziękujemy za rejestrację!</p>
                            <p>
                                Sprawdź swoją skrzynkę pocztową, aby potwierdzić rejestrację. UWAGA.
                                Jeżeli nie znajdziesz potwierdzenia rejestracji, sprawdź folder
                                SPAM.
                            </p>
                            <small>Kliknij, aby pokazać formularz</small>
                        </InformationText>
                    )}
                </Col>
            </Container>
        </>
    );
};

export default Newsletter;
