import { Col } from 'antd';
import ArticleCard from 'components/ArticleCard';
import EventCard from 'components/EventCard';
import GalleryCard from 'components/GalleryCard';
import VideoCard from 'components/VideoCard';
import WhitePaperCard from 'components/WhitePaperCard';

type ArticleType = {
    type: 'article';
    $item: Article;
};

type WhitePaperType = {
    type: 'white-paper';
    $item: WhitePaper;
};

type VideoType = {
    type: 'video';
    $item: Video;
};

type GalleryType = {
    type: 'gallery';
    $item: Gallery;
};

type EventType = {
    $item: Occurrence;
    type: 'event';
};

type ItemType = ArticleType | WhitePaperType | GalleryType | VideoType | EventType;

type Card = {
    size?: {
        span?: string | number;
        xs?: string | number;
        sm?: string | number;
        md?: string | number;
        lg?: string | number;
        xl?: string | number;
        xxl?: string | number;
    };
    noImages?: boolean;
    noTeaser?: boolean;
    url?: string;
};
type Props = Card & ItemType;

export const Item = ({ $item, type, size, noImages = false, noTeaser = false }: Props) => {
    if (type === 'article') {
        return (
            <ArticleCard
                $article={$item}
                companyAsCategory={($item.companies || []).find(
                    (company) => company.sponsor === true
                )}
                size={size}
                noImages={noImages}
                noTeaser={noTeaser}
            />
        );
    }

    if (type === 'white-paper') {
        return (
            <Col {...size}>
                <WhitePaperCard item={$item} />
            </Col>
        );
    }
    if (type === 'video') {
        return (
            <Col {...size}>
                <VideoCard video={$item} />
            </Col>
        );
    }

    if (type === 'gallery') {
        return (
            <Col {...size}>
                <GalleryCard
                    images={$item.images}
                    title={$item.title}
                    slug={$item.seoSlug}
                    category={$item.category}
                />
            </Col>
        );
    }

    if (type === 'event') {
        return (
            <Col {...size}>
                <EventCard
                    title={$item.name}
                    slug={$item.seoSlug}
                    startDate={$item.startDate}
                    endDate={$item.endDate}
                    type={$item.type}
                    logo={!noImages && $item.logo}
                />
            </Col>
        );
    }
};

export default Item;
