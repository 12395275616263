import { Row } from 'antd';
import { SectionTitle } from 'components/Section';
import Item from './Item';

type Props = {
    companies: Pick<Company, 'name' | 'seoSlug' | 'logo'>[];
};

export const ContentProvidersSection = ({ companies }: Props) => {
    return (
        <>
            <SectionTitle
                title="Dostawcy treści"
                url="/katalog-firm"
                subTitle="Partnerzy portalu"
            />
            <Row justify="center" gutter={[32, 16]}>
                {companies.map(({ seoSlug, name, logo }) => (
                    <Item key={`${seoSlug}-patronate`} company={{ seoSlug, name, logo }} />
                ))}
            </Row>
        </>
    );
};

export default ContentProvidersSection;
