import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Image from 'components/Image';
import { Category, Title } from 'components/Card';
import Card from './Card';
import FormattedDate from './FormattedDate';

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;
interface Props {
    title: string;
    type: string;
    slug: string;
    startDate: string;
    endDate: string;
    logo?: string;
    logoThumb?: string;
}

const EventCard = ({ title, type, slug, startDate, endDate, logo }: Props): JSX.Element => (
    <Card
        bordered={false}
        cover={
            logo && (
                <ImageContainer>
                    <Link href={`/wydarzenia/${slug}`}>
                        <Image height={270} width={270} alt={title} src={logo} />
                    </Link>
                </ImageContainer>
            )
        }
        style={{ boxShadow: 'none', padding: 0 }}
    >
        <FormattedDate startDate={startDate} endDate={endDate} />
        <Category>
            <Link href={`/wydarzenia?type=${type}`}>{type}</Link>
        </Category>
        <Title>
            <Link href={`/wydarzenia/${slug}`}>{title}</Link>
        </Title>
    </Card>
);

export default EventCard;
