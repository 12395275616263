import { Col, Tooltip } from 'antd';
import Link from 'next/link';
import Image from 'components/Image';

type Props = {
    company: Pick<Company, 'name' | 'seoSlug' | 'logo'>;
};

export const Item = ({ company: { seoSlug, name, logo } }: Props) => {
    return (
        <Col>
            <Link href={`/katalog-firm/${seoSlug}`}>
                <Tooltip title={name}>
                    <Image type="logo" height={72} width={72} alt={name} src={logo} />
                </Tooltip>
            </Link>
        </Col>
    );
};

export default Item;
