import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.brownishGrey};
`;

const FormattedDate = ({ startDate, endDate, separator = '.' }) => {
    const isSameDay = (a, b) => {
        return (
            a.getFullYear() === b.getFullYear() &&
            a.getMonth() === b.getMonth() &&
            a.getDate() === b.getDate()
        );
    };

    const isSameMonth = (a, b) => {
        return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth();
    };
    const OStartDate = new Date(startDate);
    const OEndDate = new Date(endDate);

    if (isSameDay(OStartDate, OEndDate)) {
        return (
            <Container>{`${OStartDate.getDate()}${separator}${
                OStartDate.getMonth() + 1
            }${separator}${OStartDate.getFullYear()}`}</Container>
        );
    }

    if (isSameMonth(OStartDate, OEndDate)) {
        return (
            <Container>{`${OStartDate.getDate()}-${OEndDate.getDate()}${separator}${
                OStartDate.getMonth() + 1
            }${separator}${OStartDate.getFullYear()}`}</Container>
        );
    }

    return (
        <Container>{`${OStartDate.getDate()}${separator}${
            OStartDate.getMonth() + 1
        }${separator}${OStartDate.getFullYear()}-${OEndDate.getDate()}${separator}${
            OEndDate.getMonth() + 1
        }${separator}${OEndDate.getFullYear()}`}</Container>
    );
};

export default FormattedDate;
